.table-container {
  margin-top: 1.5rem;
  max-width: 30%;
  font-size: 12px;
}

.table-title {
  margin-bottom: 1rem;
}

tfoot {
  margin-top: 10px;

  width: 300px;
}

.totalRows {
  width: 40%;
}
.ant-table-cell {
  font-size: 12px;
  padding-bottom: 0px;

  height: 0px;
  min-height: 5px;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 1.5px;
  text-align: center;
}
