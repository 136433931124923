.container-filter {
  display: flex;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  padding-bottom: 25px;
}

.datepicker {
  margin-right: 90px;
}
.select {
  display: flex;
  flex-direction: column;
  width: 400px;
  padding-left: 20px;
}
.btn-filter {
  display: flex;
  align-items: center;
  margin-top: 22px;
  margin-left: 5rem;
}

@media (max-width: 375px) {
  .container-filter {
    display: flex;
    flex-direction: column;
  }
}
