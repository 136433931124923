@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "~antd/dist/antd.css";

.navbar-container {
  display: flex;
  flex-direction: row;
  /* background-color: #2dc4ae; */
  min-height: 60px;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  background-color: white;
}

.logo {
  align-self: center;
  width: 160px;
  padding-left: 10px;
}

.menu-items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-self: center;

  width: 80%;
  margin-left: 1rem;
}

.menu-items h1 {
  margin: 0 0.5rem 0 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
}

.login-avatar {
  width: 200px;
  align-self: center;
  justify-items: right;
  justify-content: right;
  overflow: hidden;
}

.menu-items .link-btn {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .navbar-container {
  }

  .menu-items h1 {
    font-size: 1rem;
  }
}

@media (max-width: 475px) {
  .navbar-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    justify-content: center;
    max-width: 375px;
  }

  .menu-items .link-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    margin: 0.5rem 0 0.5rem 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: rgb(89, 170, 241);
    color: white;
  }

  .logo {
    min-height: 25px;
    display: flex;
    align-items: center;
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .login-avatar {
    display: flex;
    justify-items: center;
    align-items: center;
    overflow: unset;
    width: auto;
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 10px;
  }
}

.drop:hover {
  position: absolute;
  display: block;
  z-index: 100;
  opacity: 20;
}

.logout {
  align-items: right;
  text-align: right;
}
.logout:hover {
  cursor: pointer;
}
.login {
  align-items: right;
  text-align: right;
}
.login:hover {
  cursor: pointer;
}
