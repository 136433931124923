.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
/* .tag {
  display: flex;
  justify-content: center;
  text-align: center;
}
.tag p {
  text-align: center;
  margin-top: 12px;
} */
