.table-container-sales {
  display: flex;
  margin-top: 60px;
}
@media (max-width: 768px) {
  .table-container-sales {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.statistics {
  display: flex;
  flex-direction: row;
}

.stat {
  margin-right: 3em;
}

.table-header {
  display: flex;
  height: 55px;
  align-items: center;
}

.name-badge {
  margin-top: 10px;
}

.total-box {
  /* display: flex;
  flex-direction: column; */
  position: relative;
  margin-left: 6.5rem;
  align-items: center;
}

.total-title {
  color: #f94892;
  position: absolute;
}

.table-total {
  padding-top: 10px;
  font-size: 1.5rem;
  color: #2db7f5;
  align-items: center;
  z-index: 9;
}

.clientes-box {
  /* display: flex;
  flex-direction: column; */
  position: relative;
  margin-left: 2rem;
  align-items: center;
}

.clientes-title {
  color: #f94892;
  position: absolute;
}

.clientes-total {
  padding-top: 10px;
  font-size: 1.5rem;
  color: #2db7f5;
  align-items: center;
  z-index: 9;
}
