.ant-layout-content {
  flex: none;
  min-height: 0;
}
#components-form-demo-normal-login .login-form {
  max-width: "300px";
  padding: 10px;
  display: flex;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
  display: flex;
}
#components-form-demo-normal-login .login-form-button {
  width: "100%";
  display: flex;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10vh;
  margin-right: 10vh;
  margin-left: 10vh;
  /* background-color: red; */
}

.login-container-content {
  display: flex;
  flex-direction: column;
  padding-top: 10vh;
  /* background-color: blue; */
  align-items: center;
}

.ant-layout-content .login-container-content {
  flex-direction: row;
  text-align: center;
  justify-content: center;
  margin-top: 20px;
  padding-top: 15px;
  display: flex;
  align-items: center;
}

.login-form {
  margin-top: 10em;
  padding: 10px;
  width: 20em;
}
.login-footer {
  display: flex;
}
.space {
  display: flex;
  justify-content: space-evenly;
}
a {
  align-self: center;
}
