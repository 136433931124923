.table-container-sales {
  display: flex;
  margin-top: 60px;
}
@media (max-width: 768px) {
  .table-container-sales {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.statistics {
  display: flex;
  flex-direction: row;
}

.stat {
  margin-right: 3em;
}

.totalRetiros {
  color: "blue";
}

.expandable-table {
  /* background-color: red; */
  width: 40%;
}

.total {
  margin-top: 0.5em;
  text-align: right;
  margin-right: 1em;
}

.totalRetirosBadge {
  /* background-color: red; */
  color: red;
  font-size: small;
}

.green {
  color: green;
  font-size: small;
}
.red {
  color: red;
  font-size: small;
}
.black {
  color: black;
  font-size: small;
}

.diferencia {
  /* color: green; */
  font-size: 1.3em;
}
