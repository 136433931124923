.site-card-wrapper {
  padding: 30px;
  background: #ececec;
  flex-wrap: wrap;
}
.price-btn {
  display: flex;
  justify-content: flex-end;
}
.spacing {
  margin: 40px 0;
  justify-content: center;
  padding: 15px 0;
  /* width: 20rem; */
}
.card {
  min-height: 30rem;
  display: flex;
  flex-direction: column;
}
.title {
  text-align: center;
  padding: 20px 10px 0 0;
}
.ant-row {
  margin-left: 15px;
  padding-left: 100px;
}
