@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container {
  margin-right: 10vh;
  margin-left: 10vh;
}

.body {
  height: 100vh;
  flex-grow: 1;
}
.code-box-demo {
  text-align: center;
}
.ant-layout-header,
.ant-layout-footer {
  color: #fff;
  background: #7dbcea;
}
[data-theme="dark"] .ant-layout-header {
  background: #6aa0c7;
}
[data-theme="dark"] .ant-layout-footer {
  background: #6aa0c7;
}
.ant-layout-footer {
  line-height: 1.5;
}
.ant-layout-sider {
  color: #fff;
  line-height: 120px;
  background: #3ba0e9;
}
[data-theme="dark"] .ant-layout-sider {
  background: #3499ec;
}
.ant-layout-content {
  height: calc(100vh - 160px);
  background-color: white;

  /* background: rgba(16, 142, 233, 1); */
}
[data-theme="dark"] .ant-layout-content {
  background: #107bcb;
}
.code-box-demo > .ant-layout + .ant-layout {
  margin-top: 48px;
}

.ant-layout-header,
.ant-layout-footer,
.ant-layout-content {
}

@media (max-width: 768px) {
  .ant-layout-header,
  .ant-layout-footer,
  .ant-layout-content {
    margin: 0 0 0 0;
  }
}

@media (max-width: 425px) {
  .container {
    margin-right: 0vh;
    margin-left: 0vh;
  }

  .ant-layout-header,
  .ant-layout-footer,
  .ant-layout-content {
    margin: 0 0 0 0;
  }
}
