.wbar-container {
  /* background-color: rgb(54, 233, 48); */
  margin-bottom: 1rem;
  display: flex;
  justify-content: end;
}

.cambio {
  /* font-weight: 400; */
  font-style: italic;
}

.oficial,
.blue {
  text-align: end;
  width: 8rem;
  background-color: white;
  padding-right: 1rem;
}

.blue {
  /* font-weight: bold; */
}
