.table-sales {
  width: 380px;
  margin-bottom: 40px;
  margin-right: 50px;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #eeeeee27;
}

.summary-total {
  font-weight: 600;
}
