.table-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}
.boxes {
  /* flex: 1; */
  margin: 15px 20px;
  flex-wrap: wrap;
  text-align: center;
  flex-wrap: wrap;
}
